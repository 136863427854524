import React from "react"
import { Tabs } from "antd"
import ProducerFee from "./producerFree"
import RetailFee from "./retailFee"
import "../style.scss"

function FeeManagement(props) {
  return (
    <div className="pt-5">
      <div className="fixTab">
        <Tabs>
          <Tabs.TabPane tab="生産者・メーカー・卸向け手数料" key="item-1">
            <ProducerFee />
          </Tabs.TabPane>
          <Tabs.TabPane tab="飲食店・小売店向け手数料" key="item-2">
            <RetailFee />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  )
}

export default FeeManagement
