import React, { useEffect, useState } from "react"
import {
  Button,
  Spin,
  Table,
  InputNumber,
  Input,
  Space,
  Popconfirm,
} from "antd"
import { UpdateCollectionMedium } from "../../../../services/product"
import {
  openNotificationChangeSuccess,
  openNotificationFail,
} from "../../../../components/notification"
import { onKeyPressNumber } from "../../../../customUtil/global"
const UpdateFeeMedium = ({
  record,
  setListDataSourceOfTable,
  listDataSourceOfTable,
  listColections,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const [numberBefore, setNumberBefore] = useState(null)
  const [numberAfter, setNumberAfter] = useState(null)
  console.log("recordrecord", record)
  const handleUpdateCollectionMedium = async () => {
    setIsLoading(true)
    const { response } = await UpdateCollectionMedium(record.idMedium, {
      metadata: { feePercent: numberAfter },
    })
    if (response.status == 200) {
      setIsLoading(false)
      setNumberBefore(numberAfter)
      const indexOfRecord = listDataSourceOfTable
        .map(function (e) {
          return e.idMedium
        })
        .indexOf(record.idMedium)

      listDataSourceOfTable.splice(indexOfRecord, 1, {
        ...record,
        metadataMedium: { feePercent: numberAfter },
      })
      console.log("listDataSourceOfTableaaaa", listDataSourceOfTable)
      const listDataSourceOfTableByRecord = listDataSourceOfTable.filter(
        (el) => el.id == record.id
      )
      const listDataSourceOfTableByRecord2 = listDataSourceOfTableByRecord.filter(
        (el) => el.idMedium
      )
      console.log(
        "listDataSourceOfTableByRecord2",
        listDataSourceOfTableByRecord2
      )

      const indexOfElementChange = record.items
        .map(function (e) {
          return e.id
        })
        .indexOf(record.idMedium)
      console.log("indexOfElementChange", indexOfElementChange)

      const listItemsChange = record.items
      listItemsChange.splice(indexOfElementChange, 1, {
        id: record.idMedium,
        title: record.titleMedium,
        metadata: { feePercent: numberAfter },
      })
      console.log("listItemsChange", listItemsChange)

      listDataSourceOfTable.map((el) => {
        if (el.id == record.id) {
          return { ...el, items: listItemsChange }
        } else {
          return el
        }
      })

      setListDataSourceOfTable([...listDataSourceOfTable])
      openNotificationChangeSuccess("正常に更新されました。", "", "#389e0d")
    } else {
      openNotificationFail(
        "ただいま、サーバが混み合っています。",
        "しばらくしてから再度操作してください。",
        "#f81d22"
      )
    }
  }
  useEffect(() => {
    setNumberBefore(record.metadataMedium?.feePercent)
    setNumberAfter(record.metadataMedium?.feePercent)
  }, [record])
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Space size={5} direction="horizontal">
        <Input
          value={numberAfter}
          type="number"
          style={{ width: 46, height: 25 }}
          onKeyPress={onKeyPressNumber}
          onChange={(e) => {
            console.log(e)
            if (e.target.value) {
              setNumberAfter(Number(e.target.value))
              return
            }
            setNumberAfter(0)
          }}
        />
        <div>{"%"}</div>
      </Space>
      <Space
        size={5}
        style={{
          padding: "0px 0px 0px 1rem",
          borderLeft: "1px solid #CCCCCC",
        }}
      >
        <Button
          style={{ height: 25, fontSize: 10 }}
          danger
          disabled={numberAfter == numberBefore}
          loading={isLoading}
          onClick={handleUpdateCollectionMedium}
        >
          {"編集"}
        </Button>
        <Popconfirm
          placement="bottomLeft"
          title={
            <div style={{ fontSize: 14, fontWeight: 600 }}>
              {`削除ボタンを押下した時、すべて製品の ${record.title} の手数料は ${record.metadata.feePercent}%になります。本当に実行しますか？ `}
            </div>
          }
          onConfirm={async () => {
            setIsLoadingDelete(true)
            const { response } = await UpdateCollectionMedium(record.idMedium, {
              metadata: { feePercent: record.metadata?.feePercent },
            })
            if (response.status == 200) {
              setIsLoadingDelete(false)
              const indexOfRecord = listDataSourceOfTable
                .map(function (e) {
                  return e.idMedium
                })
                .indexOf(record.idMedium)

              const listDataSourceOfTableByRecord = listDataSourceOfTable.filter(
                (el) => el.id == record.id
              )
              const listDataSourceOfTableByRecord2 = listDataSourceOfTableByRecord.filter(
                (el) => el.idMedium
              )

              if (listDataSourceOfTableByRecord2.length < record.items.length) {
                const indexOfElementChange = record.items
                  .map(function (e) {
                    return e.id
                  })
                  .indexOf(record.idMedium)
                console.log("indexOfElementChange", indexOfElementChange)

                const listItemsChange = record.items
                listItemsChange.splice(indexOfElementChange, 1, {
                  id: record.idMedium,
                  title: record.titleMedium,
                  metadata: { feePercent: record.metadata?.feePercent },
                })
                console.log("listItemsChange", listItemsChange)

                listDataSourceOfTable.splice(indexOfRecord, 1)

                listDataSourceOfTable.map((el) => {
                  if (el.id == record.id) {
                    return { ...el, items: listItemsChange }
                  } else {
                    return el
                  }
                })
              }
              if (
                listDataSourceOfTableByRecord2.length == record.items.length
              ) {
                const indexOfFisrt = listDataSourceOfTable
                  .map(function (e) {
                    return e.id
                  })
                  .indexOf(record.id)
                const indexOfElementChange = record.items
                  .map(function (e) {
                    return e.id
                  })
                  .indexOf(record.idMedium)
                console.log("indexOfElementChange", indexOfElementChange)

                const listItemsChange = record.items
                listItemsChange.splice(indexOfElementChange, 1, {
                  id: record.idMedium,
                  title: record.titleMedium,
                  metadata: { feePercent: record.metadata?.feePercent },
                })
                console.log("listItemsChange", listItemsChange)

                const indexOfRecord2 = listDataSourceOfTableByRecord2
                  .map(function (e) {
                    return e.idMedium
                  })
                  .indexOf(record.idMedium)
                listDataSourceOfTableByRecord2.splice(indexOfRecord2, 1)
                listDataSourceOfTableByRecord2.map((el) => {
                  return { ...el, items: listItemsChange }
                })
                listDataSourceOfTableByRecord2.push({
                  id: record.id,
                  title: record.title,
                  metadata: record.metadata,
                  items: listItemsChange,
                })
                listDataSourceOfTable.splice(
                  indexOfFisrt,
                  record.items.length,
                  ...listDataSourceOfTableByRecord2
                )
              }

              console.log("listDataSourceOfTableaaaa", listDataSourceOfTable)

              setListDataSourceOfTable([...listDataSourceOfTable])
              openNotificationChangeSuccess(
                "正常に更新されました。",
                "",
                "#389e0d"
              )
            } else {
              openNotificationFail(
                "ただいま、サーバが混み合っています。",
                "しばらくしてから再度操作してください。",
                "#f81d22"
              )
            }
          }}
          okText="削除"
          cancelText="キャンセル"
        >
          <Button
            style={{ height: 25, fontSize: 10 }}
            loading={isLoadingDelete}
          >
            {"削除"}
          </Button>
        </Popconfirm>
      </Space>
    </div>
  )
}

export default UpdateFeeMedium
