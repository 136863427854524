import React from "react"
import { Link } from "gatsby"
import { Content } from "antd/lib/layout/layout"
import { Breadcrumb } from "antd"
import { Tabs } from "antd"
import FeeManagement from "./feeManagement"
import FeeTax from "./feeTax"

function PostManagementContent(props) {
  return (
    <div>
      <Content>
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "10px",
          }}
        >
          <Breadcrumb separator={">"}>
            <Breadcrumb.Item>
              <Link to="/home">HOME</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="">掲載管理</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              width: "100%",
              height: "105px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "24px",
                lineHeight: "33px",
                letterSpacing: "0.3px",
                display: "block",
              }}
            >
              掲載管理
            </h1>
          </div>
          <div>
            <Tabs defaultActiveKey={"item-3"}>
              <Tabs.TabPane tab="企業管理" key="item-1">
                企業管理
              </Tabs.TabPane>
              <Tabs.TabPane tab="商品管理" key="item-2">
                商品管理
              </Tabs.TabPane>
              <Tabs.TabPane tab="手数料管理" key="item-3">
                <FeeManagement />
              </Tabs.TabPane>
              <Tabs.TabPane tab="消費税管理" key="item-4">
                <FeeTax />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </Content>
    </div>
  )
}

export default PostManagementContent
